
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Application from "./Application";

function App() {
  return (
      <BrowserRouter>
        <div className="App">
          <Application/>
        </div>
      </BrowserRouter>
  );
}

export default App;
