import { useEffect, useState } from "react";
import { Container, Stack } from "react-bootstrap";
import { useParams } from "react-router";
import { getBrandedTourDetails } from "../firebase";

import html2canvas from "html2canvas";

const RicahTourPage = (props) => {
  const [brandImageUrl, setBrandImageUrl] = useState("");
  const [brandUrl, setBrandUrl] = useState("");
  let { tourId } = useParams();

  const screenshot = () => {
    const screenshotTarget = document.body;

    html2canvas(screenshotTarget).then((canvas) => {
      const base64image = canvas.toDataURL("image/png");
      window.location.href = base64image;
    });
  };
  return (
    <div className="branded-tour-iframe">
      <iframe
        className="branded-tour-iframe"
        title="Tour"
        name="Tour"
        width="100%"
        height="100%"
        src={`https://embed.ricoh360.com/${tourId}`}
        allow="xr-spatial-tracking"
      ></iframe>
      <Stack direction="horizontal" className="branded-tour-brand">
        {/* <span
          className={"material-symbols-outlined fullscreen"}
          onClick={() => screenshot()}
        >
          screenshot_monitor
        </span> */}
        <a href={`https://embed.vuprop.com/tour/${tourId}`} target="#">
          <span className={"material-symbols-outlined fullscreen"}>
            fullscreen
          </span>
        </a>
      </Stack>
    </div>
  );
};

export default RicahTourPage;
