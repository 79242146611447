import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import BrandedTourPage from "./pages/BrandedTourPage";
import RicahTourPage from "./pages/RicahTourPage";
import CustomTourPage from "./pages/CustomTourPage";

function Application() {
  return (
    <Routes>
      <Route path="/brand/:tourId" element={<BrandedTourPage />} />
      <Route path="/tour/:tourId" element={<RicahTourPage />} />
      <Route
        path="/tour/:tourId/fullscreen"
        element={<RicahTourPage fullscreen={true} />}
      />

      {/* <Route path="/" element={<CustomTourPage />} /> */}
    </Routes>
  );
}

export default Application;
