import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router";
import { getBrandedTourDetails } from "../firebase";

const BrandedTourPage = () => {
  const [brandImageUrl, setBrandImageUrl] = useState("");
  const [brandUrl, setBrandUrl] = useState("");
  const [matterportId, setMatterportId] = useState("");
  let { tourId } = useParams();

  useEffect(() => {
    getBrandedTourDetails(tourId).then((res) => {
      setBrandImageUrl(res.brand_image_url);
      setBrandUrl(res.brand_url);
      setMatterportId(res.matterport_id);
    });
  }, [tourId]);
  return (
    <div className="branded-tour-iframe">
      <iframe
        className="branded-tour-iframe"
        title="Branded Tour"
        name="Branded Tour"
        width="100%"
        height="100%"
        src={`https://my.matterport.com/show/?m=${matterportId}&play=1`}
        allow="xr-spatial-tracking"
      ></iframe>
      <a href={brandUrl} className="branded-tour-brand" target="#">
        <img alt="" src={brandImageUrl} width="100" height="50" />
      </a>
    </div>
  );
};

export default BrandedTourPage;
