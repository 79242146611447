// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import { getFunctions, httpsCallable } from "firebase/functions";

import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCOhWgSt3Y3x-zgwP3S48sBtnK223VzesM",

  authDomain: "vuprop-a0b9f.firebaseapp.com",

  projectId: "vuprop-a0b9f",

  storageBucket: "vuprop-a0b9f.appspot.com",

  messagingSenderId: "84934060029",

  appId: "1:84934060029:web:1bd2790d40f1d6946fdb94",

  measurementId: "G-R03BPJYN25",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const firestoreDb = getFirestore(app);

const functions = getFunctions();

const storage = getStorage();

export const auth = getAuth(app);

// Tour Functions

export async function getBrandedTourDetails(tourId) {
  let query = doc(firestoreDb, `branded-tours`, `${tourId}`);
  let tour = await getDoc(query);
  if (tour.exists()) {
    return tour.data();
  }
  return {};
}
